body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.map-container {
    height: 100vh;
    width: 100vw;
}

.loader-container {
  content:"";
  position: absolute;
  min-width: 100vw; 
  min-height: 100vh;
  background-color:rgba(255,255,255,0.5);
  z-index: 50;
  left: 0;
  top: 0;
}


.inner {
  margin-left: 45%;
  margin-top: 20%;
}

.mapbox-improve-map {
    display: none;
}
   
.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}
